
<template>
  <div
    class="logo"
  >
    <div class="logo__container">
      <router-link
        to="/"
        class="logo__link"
      >
        <svg class="logo__el" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 667.757 726.486">
          <path class="logo__p3" d="M374.9,623.3c-26.3,0.1-65.7,0.2-117,0.2c-55.6,0-105.8,0-106.3,0l-39.6,0V142.7c-34.7,0-69.5,0-104.2,0c-2.6,0-5.1,0-7.7,0
            c0,195,0,583.7,0,583.7s150.8,0.2,223.1-0.1c31-0.1,61.5-4,91.3-13c59.5-18,107.2-51.5,143.9-99.5
            C431.9,620.1,404.5,623.2,374.9,623.3z"/>
          <path class="logo__p2" id="_x30_1" d="M151.6,583.8c0,0,0-388.7,0-583.7c2.6,0,5.2,0,7.7,0c70.4,0,140.9-0.1,211.4,0c51.2,0.1,100.4,9.1,146.1,33.3
            c49.3,26.1,88.3,63.1,115.4,111.9c32.1,57.7,40.1,119.9,33.2,184.8c-4.6,43.3-17.3,83.8-41,120.4c-38.2,59.1-90.7,99.7-158.4,120.2
            c-29.8,9-60.3,12.9-91.3,13C302.4,584,151.6,583.8,151.6,583.8z M278.1,466.1c25.9,0,51.1,0.3,76.2-0.1c16-0.3,32.2-0.6,47.9-3
            c51.3-7.8,89.4-36,114.3-80.6c25.5-45.6,28.1-94.8,15.2-144.8C515.1,173.2,460,125,394.1,119.8c-37.9-3-116-1.9-116-1.9
            S278.1,349.6,278.1,466.1z"/>
          <path class="logo__p1" d="M481.8,363.1c7.2-12.9,12.2-26.4,15.1-40.5c-4.5-11.7-9.9-23.2-16.2-34.6c-27.2-48.8-66.2-85.8-115.4-111.9
            c-14.5-7.7-29.4-13.8-44.6-18.7c-1,0-1.9,0-2.9,0l0,132.4c30.1,21.2,52.7,53.1,62.4,90.5c3.9,15,6.3,29.8,7.2,44.6
            c3-0.3,6-0.6,8.9-1.1C434.3,417.9,462.3,398.1,481.8,363.1z"/>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    data() {
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  });
</script>
